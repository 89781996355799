import { Add } from '@mui/icons-material';
import { Box, Button, ButtonProps, Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridToolbarLayoutsButton, ServerSideState } from '@price-for-profit/data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useUserPermissions } from 'shared/hooks';
import { useSelectedCustomerPrice } from 'shared/providers/provideSelectedCustomerPrice';
import { ProductPricesToggleCurrency } from '../productPrices';
import { CustomerPricesMassActionModal } from './CustomerPricesMassActionModal';

type ProductPricesCustomToolbarProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
    mutationLoading: boolean;
};

export function CustomerPricesCustomToolbar({
    state,
    invalidate,
    rowCount,
    mutationLoading,
}: ProductPricesCustomToolbarProps) {
    const {
        permittedRowLevels: {
            permitted: { businessLines: permittedBusinessLines },
        },
        data: { isRevisedCustomerPriceWriteAllowed, isAddCustomerPriceAllowed },
    } = useUserPermissions();

    const { openQuoteModal } = useSelectedCustomerPrice();
    const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleAddExisting = () => {
        setIsAddRecordModalOpen(false);
        navigate(ROUTES.customerPricesAddExistingSAPRecord);
    };

    const handleAddProspective = () => {
        setIsAddRecordModalOpen(false);
        navigate(ROUTES.customerPricesAddNewRecord);
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarLayoutsButton localStorageName='nouryon-pmt-customer' />
            {isRevisedCustomerPriceWriteAllowed && (
                <CustomerPricesMassActionModal state={state} invalidate={invalidate} rowCount={rowCount} />
            )}
            <ProductPricesToggleCurrency label={'Document Currency'} mutationLoading={mutationLoading} />
            {isAddCustomerPriceAllowed && (
                <Button
                    color='primary'
                    startIcon={<Add />}
                    size='small'
                    onClick={() => setIsAddRecordModalOpen(true)}
                    disabled={!permittedBusinessLines.length}
                >
                    Add record
                </Button>
            )}

            <Dialog onClose={() => setIsAddRecordModalOpen(false)} open={isAddRecordModalOpen}>
                <DialogTitle> Add New Record </DialogTitle>
                <DialogContent>
                    <Box>
                        <Button onClick={handleAddExisting}>Add Existing SAP Customer</Button>
                        <Button onClick={handleAddProspective}>Add Prospective Customer</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Box flexGrow={1} />
            <CustomerPricesGenerateQuoteButton state={state} openQuoteModal={openQuoteModal} />
        </GridToolbarContainer>
    );
}

type CustomerPricesGenerateQuoteButtonProps = { state: ServerSideState; openQuoteModal: () => void };

export function CustomerPricesGenerateQuoteButton({ state, openQuoteModal }: CustomerPricesGenerateQuoteButtonProps) {
    const {
        permittedRowLevels: {
            permitted: { businessLines, orgRegions },
        },
        featureFlags: { isViewCpQuoteAllowed },
    } = useUserPermissions();

    if (!isViewCpQuoteAllowed) return null;

    if (state.filterModel.linkOperator === 'or')
        return <DisabledGenerateQuoteButton tooltip={'Must filter using "AND" instead of "OR"'} />;

    if (businessLines.length === 0)
        return <DisabledGenerateQuoteButton tooltip={'Must have permission to view a Business Line'} />;
    if (orgRegions.length === 0)
        return <DisabledGenerateQuoteButton tooltip={'Must have permission to view an Org Region'} />;

    if (businessLines.length > 1) {
        const businessLineFilter = state.filterModel.items.find(
            relevantFilter => relevantFilter.columnField === 'businessLine'
        );
        if (!businessLineFilter) return <DisabledGenerateQuoteButton tooltip={'Must filter Business Line'} />;
        if (businessLineFilter.operatorValue !== 'equals')
            return <DisabledGenerateQuoteButton tooltip={'Must filter Business Line using "equals" operator'} />;
    }

    if (orgRegions.length > 1) {
        const orgRegionFilter = state.filterModel.items.find(
            relevantFilter => relevantFilter.columnField === 'orgRegion'
        );
        if (!orgRegionFilter) return <DisabledGenerateQuoteButton tooltip={'Must filter Org Region'} />;
        if (orgRegionFilter.operatorValue !== 'equals')
            return <DisabledGenerateQuoteButton tooltip={'Must filter Org Region using "equals" operator'} />;
    }

    const soldToFilter = state.filterModel.items.find(relevantFilter => relevantFilter.columnField === 'soldToId');
    if (!soldToFilter) return <DisabledGenerateQuoteButton tooltip={'Must filter Sold To Number'} />;
    if (soldToFilter.operatorValue !== 'equals')
        return <DisabledGenerateQuoteButton tooltip={'Must filter Sold To Number using "equals" operator'} />;

    return <GenerateQuoteButton onClick={openQuoteModal} />;
}

export const DisabledGenerateQuoteButton = ({ tooltip }: { tooltip: string }) => (
    <Tooltip title={tooltip}>
        <div>
            <GenerateQuoteButton disabled />
        </div>
    </Tooltip>
);

export const GenerateQuoteButton = (props: ButtonProps) => (
    <Button {...{ ...props, variant: 'contained' }} sx={{ mb: 2 }}>
        Generate Quote
    </Button>
);
