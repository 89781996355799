import { Add } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridToolbarLayoutsButton, ServerSideState } from '@price-for-profit/data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useUserPermissions } from 'shared/hooks';
import { useSelectedTemporalCustomerPrice } from 'shared/providers';
import { CustomerPricesGenerateQuoteButton } from '../customerPrices';
import { ProductPricesToggleCurrency } from '../productPrices';
import { TemporalCustomerPricesMassActionModal } from './TemporalCustomerPricesMassActionModal';

type TemporalCustomerPricesCustomToolbarProps = {
    state: ServerSideState;
    invalidate: () => void;
    rowCount: number;
    mutationLoading: boolean;
};

export function TemporalCustomerPricesCustomToolbar({
    state,
    invalidate,
    rowCount,
    mutationLoading,
}: TemporalCustomerPricesCustomToolbarProps) {
    const {
        permittedRowLevels: {
            permitted: { businessLines: permittedBusinessLines },
        },
        data: { isRevisedCustomerPriceWriteAllowed, isAddCustomerPriceAllowed },
    } = useUserPermissions();

    const { openQuoteModal } = useSelectedTemporalCustomerPrice();
    const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleAddExisting = () => {
        setIsAddRecordModalOpen(false);
        navigate(ROUTES.customerPricesAddExistingSAPRecord);
    };

    const handleAddProspective = () => {
        setIsAddRecordModalOpen(false);
        navigate(ROUTES.customerPricesAddNewRecord);
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarLayoutsButton localStorageName='nouryon-pmt-customer' />
            {isRevisedCustomerPriceWriteAllowed && (
                <TemporalCustomerPricesMassActionModal state={state} invalidate={invalidate} rowCount={rowCount} />
            )}

            <ProductPricesToggleCurrency label={'Document Currency'} mutationLoading={mutationLoading} />
            {isAddCustomerPriceAllowed && (
                <Button
                    color='primary'
                    startIcon={<Add />}
                    size='small'
                    onClick={() => setIsAddRecordModalOpen(true)}
                    disabled={!permittedBusinessLines.length}
                >
                    Add record
                </Button>
            )}

            <Dialog onClose={() => setIsAddRecordModalOpen(false)} open={isAddRecordModalOpen}>
                <DialogTitle> Add New Record </DialogTitle>
                <DialogContent>
                    <Box>
                        <Button onClick={handleAddExisting}>Add Existing SAP Customer</Button>
                        <Button onClick={handleAddProspective}>Add Prospective Customer</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Box flexGrow={1} />
            {<CustomerPricesGenerateQuoteButton state={state} openQuoteModal={openQuoteModal} />}
        </GridToolbarContainer>
    );
}
