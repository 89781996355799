import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { SHORT_DATE_COLUMN } from 'shared/components/app/DataGridCells';
import { CustomerPricesForeignCurrencyRenderCell } from 'shared/components/app/customerPrices/CustomerPricesForeignCurrencyRenderCell';
import { MASS_ACTION_TYPES } from 'shared/constants';
import { ExchangeRatesContext } from 'shared/providers';
import { ICustomerPricesMassActionParameters, ICustomerPricesMassActionValidation } from 'shared/types';
import { customerPricesForeignCurrencyValueGetterCurried } from 'shared/utility';

export const getValueColumn = ({
    headerName,
    fieldType,
    selectedType,
    exchangeRatesContext: { isForeignCurrency, exchangeRates },
}: {
    headerName: string;
    fieldType: 'old' | 'new';
    selectedType: ICustomerPricesMassActionParameters['Type'];
    exchangeRatesContext: ExchangeRatesContext;
}) => {
    const isValidityDatesType = selectedType === MASS_ACTION_TYPES.VALIDITY_DATES;

    const numberColDef = {
        type: 'number',
        valueGetter: (params: GridValueGetterParams<number, ICustomerPricesMassActionValidation>) => {
            if (params.row.field.includes('scaleQty')) return params.value;
            return customerPricesForeignCurrencyValueGetterCurried({ isForeignCurrency, exchangeRates })(params);
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.row.field.includes('scaleQty')) return <div>{params.value}</div>;
            return <CustomerPricesForeignCurrencyRenderCell {...params} />;
        },
    };

    return {
        headerName,
        field: isValidityDatesType
            ? (fieldType === 'old' && 'oldDate') || 'newDate'
            : (fieldType === 'old' && 'oldValue') || 'newValue',
        ...(isValidityDatesType ? SHORT_DATE_COLUMN : numberColDef),
        minWidth: 150,
    };
};
