import { Box } from '@mui/system';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { ReactElement } from 'react';
import { useExchangeRates } from 'shared/providers';
import {
    ISpGetCustomerPricesExceptions,
    IViewCustomerPrices,
    IViewProductPrices,
    IViewTemporalCustomerPricesPage,
} from 'shared/types';

export function UnitOfMeasureCell(
    params: Readonly<
        GridRenderCellParams<
            string,
            IViewCustomerPrices | ISpGetCustomerPricesExceptions | IViewProductPrices | IViewTemporalCustomerPricesPage,
            string
        >
    >
): ReactElement {
    const { isForeignCurrency } = useExchangeRates();
    const apiRef = useGridApiContext();
    if (params?.row?.id) {
        const rowMode = apiRef.current.getCellMode(params?.row?.id, 'uom');
        if (rowMode === 'edit') {
            apiRef.current.stopRowEditMode({
                id: params?.row?.id,
                ignoreModifications: true,
            });
        }
    }

    const cellClassName = isForeignCurrency ? '' : 'editable-cell';

    return <Box className={cellClassName}>{params.row.uom}</Box>;
}
