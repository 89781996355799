import { Stack } from '@mui/system';
import { PageLayout } from 'shared/components/layouts';
import { TemporalCustomerPriceAddExisting } from './temporalCustomerPriceAddExisting';

export function TemporalCustomerPricesAddExistingResolver() {
    return (
        <PageLayout pageName='Add Existing SAP Customer'>
            <Stack gap={2} height='calc(100vh - 300px)' width={'100%'}>
                <TemporalCustomerPriceAddExisting />
            </Stack>
        </PageLayout>
    );
}
