import { Box, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useTemporalCustomerPricesSubmitMutation } from 'shared/mutations/temporalCustomerPricesSubmitMutation';
import { IViewTemporalCustomerPricesPage } from 'shared/types';

export function TemporalCustomerPricesSubmitAction({
    params,
}: {
    params: GridRowParams<IViewTemporalCustomerPricesPage>;
}) {
    const { onSubmit, isLoading, isSubmitAllowed } = useTemporalCustomerPricesSubmitMutation();

    const validate = () => {
        return Boolean(params?.row?.scaleQty01 && params?.row?.amount01);
    };

    const isSubmitAllowedScalePrice = params.row.isScalePrice ? validate() : true;
    if (params.row.status === 'Approved') return <Box>Submitted</Box>;

    return (
        <Button
            variant='contained'
            onClick={() => {
                onSubmit(params.row);
            }}
            disabled={isLoading || !isSubmitAllowed(params.row) || !isSubmitAllowedScalePrice}
        >
            Submit
        </Button>
    );
}
