import { Box } from '@mui/system';
import { EmbedIndividualAnalytic, useAvailableAnalyticsReports } from 'packages/individualAnalytics';
import { models } from 'powerbi-client';
import { EmbedProps } from 'powerbi-client-react';
import Loading from 'shared/components/routing/loading';
import { IViewCustomerPrices, IViewTemporalCustomerPricesPage } from 'shared/types';

interface CustomerPriceDrawerPricingAnalyticsProps {
    selectedCustomerPrice?: IViewCustomerPrices | IViewTemporalCustomerPricesPage;
    isVisible: boolean;
}

const VISUALS = [
    {
        reportName: 'Application Pricing Analytics Sales App',
        // pageTitle: 'price vs volume scatter'
        pageName: 'ReportSection69346dc7bca37e6d2f05',
        // visualName: 'Unit Price vs. Volume (Last 3 Months)',
        visualName: 'c8648e067f7af99ced0a',
        filters: [],
    },
    {
        reportName: 'Application Pricing Analytics Sales App',
        // pageTitle: 'unit price bins',
        pageName: 'ReportSection6cb8567b804182012777',
        // visualName: 'Count of Reference Document by Unit Price (Last 12 Months)',
        visualName: 'ccc48d133d9f60ac4d43',
        filters: [],
    },
    {
        reportName: 'Application Pricing Analytics Sales App',
        // pageTitle: 'volume, price, margin',
        pageName: 'ReportSectioneac70adc6b03189493d5',
        // visualName: "Volume, Price per Unit UOM, CM per Unit UOM by Month (Last 12 Months)",
        visualName: 'b9a9c11e35d449ba954d',
        filters: [],
    },
];

export function CustomerPriceDrawerPricingAnalytics({
    selectedCustomerPrice,
    isVisible,
}: CustomerPriceDrawerPricingAnalyticsProps) {
    const { reportQuery } = useAvailableAnalyticsReports();

    if (reportQuery.isLoading) {
        return <Loading />;
    }

    const productFilters = [
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: 'Transaction Data',
                column: 'businessLine',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.businessLine || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: 'Transaction Data',
                column: 'materialId',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.materialId || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: '2023 AG',
                column: 'Ship-To Party',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.shipToId || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: '2023 AG',
                column: 'Sold- To',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.soldToId || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: 'Transaction Data',
                column: 'application',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.application || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
        {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: 'Transaction Data',
                column: 'orgRegion',
            },
            filterType: 1,
            operator: 'In',
            values: [selectedCustomerPrice?.orgRegion || 'Random text to ensure no results'],
            requireSingleSelection: false,
        },
    ];

    const analyticsEmbedPropsList: (EmbedProps | null)[] = VISUALS.map((visual, index) => {
        const analyticsReport = reportQuery?.data?.find(
            r => r.reportName === 'Application Pricing Analytics Sales App'
        );
        if (!analyticsReport) {
            return null;
        }

        const embedProps: EmbedProps = {
            embedConfig: {
                type: 'visual',
                ...{
                    ...visual,
                    filters: [...visual.filters, ...productFilters],
                },
                ...analyticsReport,
                tokenType: models.TokenType.Embed,
                settings: {
                    layoutType: 1, // Custom
                    customLayout: {
                        pageSize: {
                            type: 0, // widescreen
                        },
                        displayOption: 1, // fit to width
                    },
                    panes: {
                        filters: {
                            visible: false,
                        },
                        pageNavigation: {
                            visible: false,
                        },
                    },
                    background: 1, // transparent
                },
            },
            // Needed to access powerbi api's for seeing/changing config
            // getEmbeddedComponent: embeddedReport => {
            //     setReportState(prevState => ({ ...prevState, [index]: embeddedReport } as ReportState));
            // },
        };
        return embedProps;
    });

    return (
        <Box
            display={isVisible ? 'flex' : 'none'}
            gap={2}
            p={4}
            height='75%'
            justifyContent={'space-around'}
            alignItems={'center'}
            sx={{
                '& iframe': {
                    borderWidth: '0',
                    height: '100%',
                    width: '100%',
                },
            }}
        >
            {analyticsEmbedPropsList.map((analyticsEmbedProps, index) => {
                if (!analyticsEmbedProps) {
                    return <div key={index}>Analytics failed to load</div>;
                }
                return (
                    <EmbedIndividualAnalytic
                        key={index}
                        analyticsEmbedProps={analyticsEmbedProps}
                        height='100%'
                        width='100%'
                    />
                );
            })}
        </Box>
    );
}
