import { useService } from '@insight2profit/drive-app';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { ServerSideState } from '@price-for-profit/data-grid';
import { QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useI2PAllQuery } from 'shared/hooks/use-i2p-query';
import { IViewTemporalCustomerPricesPage } from 'shared/types';

const errorMessage = 'Failed to load product prices Locks';

type UseTemporalCustomerPricesQuoteQueryProps = {
    state: ServerSideState;
    enabled?: boolean;
};

export function useTemporalCustomerPricesQuoteQuery({
    state,
    enabled = true,
}: UseTemporalCustomerPricesQuoteQueryProps) {
    const { temporalCustomerPricesService, quoteMostRecentApprovedService } = useService();
    const { customerPricesPermittedRowLevels } = useUserPermissions();

    const { data, isLoading, isFetching, invalidateQuery } = useI2PAllQuery<IViewTemporalCustomerPricesPage[]>({
        queryName: QUERY_KEYS.customerPricesQuote,
        additionalQueryKeys: [state],
        queryFn: async () => {
            const lastApprovedFilterItems: GridFilterItem[] = state.filterModel.items.filter(item =>
                ['businessLine', 'orgRegion', 'soldToId'].includes(item.columnField)
            );
            const lastApprovedState: ServerSideState = {
                ...state,
                filterModel: { ...state.filterModel, items: lastApprovedFilterItems },
                sortModel: [],
            };

            const allCustomerPricesRowsPromise = temporalCustomerPricesService.getAllGridData({
                state,
                customerPricesPermittedRowLevels,
            });

            const quoteMostRecentApprovedRowsPromise = quoteMostRecentApprovedService.getAll({
                state: lastApprovedState,
            });

            const [allCustomerPricesRows, quoteMostRecentApprovedRows] = await Promise.all([
                allCustomerPricesRowsPromise,
                quoteMostRecentApprovedRowsPromise,
            ]);

            return allCustomerPricesRows.map(row => {
                let mostRecentApprovedRow;
                if (row.status !== 'Approval Required') {
                    mostRecentApprovedRow = quoteMostRecentApprovedRows.find(
                        quote =>
                            quote.soldToId === row.soldToId &&
                            quote.shipToId === row.shipToId &&
                            quote.materialId === row.materialId &&
                            quote.businessLine === row.businessLine
                    );
                }
                if (!mostRecentApprovedRow) {
                    return row;
                }

                return { ...row, ...mostRecentApprovedRow };
            });
        },
        errorMessage,
        enabled,
        keepPreviousData: false,
    });

    return {
        data: data || [],
        isLoading,
        isFetching,
        invalidateQuery,
    };
}
