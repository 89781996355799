import { GridColumns } from '@mui/x-data-grid-premium';
import { getValueColumn } from './getValueColumn';
import { ICustomerPricesMassActionParameters } from 'shared/types';
import { ExchangeRatesContext } from 'shared/providers';

interface IGetCustomerPricesMassActionValidationColumnsProps {
    selectedType: ICustomerPricesMassActionParameters['Type'];
    exchangeRatesContext: ExchangeRatesContext;
}

export const getCustomterPricesMassActionValidationColumns = ({
    selectedType,
    exchangeRatesContext,
}: IGetCustomerPricesMassActionValidationColumnsProps): GridColumns => [
    {
        headerName: 'ID',
        field: 'massActionId',
        hide: true,
    },
    {
        headerName: 'material',
        field: 'material',
    },
    {
        headerName: 'Material ID',
        field: 'materialId',
        width: 200,
    },
    {
        headerName: 'Sold To ID',
        field: 'soldToId',
        width: 200,
    },
    {
        headerName: 'Sold To',
        field: 'soldTo',
        width: 200,
    },
    {
        headerName: 'Ship To ID',
        field: 'shipToId',
        width: 200,
    },
    {
        headerName: 'Ship To',
        field: 'shipTo',
        width: 200,
    },
    getValueColumn({
        headerName: 'Old Value',
        fieldType: 'old',
        selectedType,
        exchangeRatesContext,
    }),
    getValueColumn({
        headerName: 'New Value',
        fieldType: 'new',
        selectedType,
        exchangeRatesContext,
    }),
    {
        headerName: 'Invalidation Reason',
        field: 'invalidationReason',
        maxWidth: 300,
    },
];
