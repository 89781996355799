import { CLIENT_ID } from '@insight2profit/drive-app';
import { DataAccessQueryResponse, DataAccessResponse, IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL } from 'shared/constants';

interface TemporalUserInfo {
    userDisplayName: string;
    userEmail?: string;
    userId?: string;
}

interface CreatedByTemporalColumns {
    createdByDisplayName: string;
    createdByEmail?: string;
    createdByUserId?: string;
    createdReason: string;
}

interface UpdatedByTemporalColumns {
    updatedByDisplayName: string;
    updatedByEmail?: string;
    updatedByUserId?: string;
    updatedReason: string;
}

export type ITemporalTable = CreatedByTemporalColumns &
    UpdatedByTemporalColumns & {
        isDeleted: boolean;
    };

type GetByIdParams = {
    primaryKey: number | string;
    isHistorical?: boolean;
};

export type AddTableRow<Table extends ITemporalTable> = Omit<
    Table,
    keyof CreatedByTemporalColumns | keyof UpdatedByTemporalColumns | 'isDeleted'
>;

type AddParams<Table extends ITemporalTable> = {
    userInfo: TemporalUserInfo;
    tableRow: AddTableRow<Table>;
    reason: string;
};

type SoftEditParams<Table extends ITemporalTable> = {
    userInfo: TemporalUserInfo;
    updatedColumns: Partial<Table>;
    primaryKey: number | string;
    reason?: string;
    oldTableRow?: Table;
};

type SoftDeleteParams = {
    userInfo: TemporalUserInfo;
    primaryKey: number | string;
    reason: string;
};

type SoftDeleteResponse<Table extends ITemporalTable> = {
    patchResult: DataAccessResponse<Table>;
    deleteResult: DataAccessResponse<Table>;
};

export interface ITemporalTableCrudService<Table extends ITemporalTable> {
    getById({ primaryKey, isHistorical }: GetByIdParams): Promise<DataAccessQueryResponse<Table>>;
    add({ userInfo, tableRow, reason }: AddParams<Table>): Promise<DataAccessResponse<Table>>;
    softEdit({
        userInfo,
        updatedColumns,
        primaryKey,
        reason,
        oldTableRow,
    }: SoftEditParams<Table>): Promise<DataAccessResponse<Table>>;
    softDelete({ userInfo, primaryKey, reason }: SoftDeleteParams): Promise<SoftDeleteResponse<Table>>;
}

export class TemporalTableCrudService<Table extends ITemporalTable> implements ITemporalTableCrudService<Table> {
    constructor(protected dasService: IDataAccessService, private tableId: string) {}

    async getById({ primaryKey, isHistorical = false }: GetByIdParams) {
        return this.dasService.getSingle<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: isHistorical ? `${this.tableId}History` : this.tableId,
            key: primaryKey.toString(),
        });
    }

    async add({ userInfo, tableRow, reason }: AddParams<Table>) {
        const createdByTemporalColumns: CreatedByTemporalColumns = {
            createdByDisplayName: userInfo.userDisplayName,
            createdByEmail: userInfo.userEmail,
            createdByUserId: userInfo.userId,
            createdReason: reason,
        };
        const updatedByTemporalColumns: UpdatedByTemporalColumns = {
            updatedByDisplayName: userInfo.userDisplayName,
            updatedByEmail: userInfo.userEmail,
            updatedByUserId: userInfo.userId,
            updatedReason: reason,
        };

        const modifiedTableRow: Table = {
            ...tableRow,
            ...createdByTemporalColumns,
            ...updatedByTemporalColumns,
            isDeleted: false,
        } as Table;

        return this.dasService.addRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.tableId,
            payload: modifiedTableRow,
        });
    }

    async softEdit({
        userInfo,
        updatedColumns,
        primaryKey,
        reason = 'Edit',
        oldTableRow,
    }: SoftEditParams<Table>): Promise<DataAccessResponse<Table>> {
        const originalRow = oldTableRow || (await this.getById({ primaryKey })).data;

        const updatedByTemporalColumns: UpdatedByTemporalColumns = {
            updatedByDisplayName: userInfo.userDisplayName,
            updatedByEmail: userInfo.userEmail,
            updatedByUserId: userInfo.userId,
            updatedReason: reason,
        };

        const modifiedTableRow: Table = {
            ...originalRow,
            ...updatedColumns,
            ...updatedByTemporalColumns,
        } as Table;

        return this.dasService.updateRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.tableId,
            payload: modifiedTableRow,
        });
    }

    async softDelete({ userInfo, primaryKey, reason }: SoftDeleteParams): Promise<SoftDeleteResponse<Table>> {
        const patchResult = await this.dasService.patchRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.tableId,
            key: primaryKey.toString(),
            operations: [
                {
                    op: 'replace',
                    path: '/isDeleted',
                    value: true,
                },
                {
                    op: 'replace',
                    path: '/updatedByDisplayName',
                    value: userInfo.userDisplayName,
                },
                {
                    op: 'replace',
                    path: '/updatedByEmail',
                    value: userInfo.userEmail,
                },
                {
                    op: 'replace',
                    path: '/updatedByUserId',
                    value: userInfo.userId,
                },
                {
                    op: 'replace',
                    path: '/updatedReason',
                    value: reason,
                },
            ],
        });

        const deleteResult = await this.dasService.deleteRow<Table, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: this.tableId,
            key: primaryKey.toString(),
        });

        return { patchResult, deleteResult };
    }
}
