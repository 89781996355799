import LaunchIcon from '@mui/icons-material/Launch';
import { Box } from '@mui/system';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useSelectedTemporalCustomerPrice } from 'shared/providers';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { TooltipIconButton } from '../../productPrices';

type TemporalCustomerPricesExceptionsActionsProps = {
    params: GridRowParams<IViewTemporalCustomerPricesPage>;
};

export function TemporalCustomerPricesExceptionsActions({ params }: TemporalCustomerPricesExceptionsActionsProps) {
    const { openExceptionsModal } = useSelectedTemporalCustomerPrice();

    return [
        <Box key={`exceptions-dialog-${params.row.id}`}>
            <TooltipIconButton
                tooltip='Exceptions'
                onClick={() => openExceptionsModal(params.row)}
                Icon={LaunchIcon}
                color='primary.main'
                isDisabled={false}
            />
        </Box>,
    ];
}
