import { useService, useUser } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { usePricesInvalidator } from 'shared/providers';
import { ITableTemporalCustomerPrices, IViewTemporalCustomerPricesPage } from 'shared/types';

export function useTemporalCustomerPriceRejectMutation() {
    const { temporalCustomerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();
    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableTemporalCustomerPrices>;
            newViewRow: IViewTemporalCustomerPricesPage;
        },
        Error,
        IViewTemporalCustomerPricesPage
    >({
        mutationKey: ['RejectTemporalCustomerPrice'],
        mutationFn: async row => {
            return await temporalCustomerPricesService.rejectRow(row, user?.displayName || '');
        },
        onSuccess: () => {
            enqueueSnackbar('Approval submitted', { variant: 'success' });
            invalidateQuery();
        },
        onError: error => {
            enqueueSnackbar('There was an error submitting the approval', { variant: 'error' });
        },
    });
    return mutation;
}
