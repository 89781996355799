import { useService, useUser } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { MASS_ACTION_TYPES } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useExchangeRates } from 'shared/providers';
import { ICustomerPricesMassActionForm, ITemporalCustomerPricesMassActionParameters } from 'shared/types';

export type TemporalCustomerPricesMassActionMutationParams = {
    state: ServerSideState;
    parameters: ITemporalCustomerPricesMassActionParameters;
};

export type DevUseCustomerPricesMassActionMutationProps = {
    state: ServerSideState;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

export function useTemporalCustomerPricesMassActionMutation({
    state,
    onSuccess,
    onError,
}: DevUseCustomerPricesMassActionMutationProps) {
    const { temporalCustomerPricesService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    // const { invalidateQuery: invalidateSummaryQuery } = useCustomerPricesSummary();
    const { customerPricesPermittedRowLevels, userApproverTier } = useUserPermissions();
    const user = useUser();

    const { isForeignCurrency } = useExchangeRates();

    const mutation = useMutation<void, Error, TemporalCustomerPricesMassActionMutationParams>({
        mutationKey: ['temporalCustomerPricesMassAction'],
        mutationFn: async ({ state, parameters }) => {
            return await temporalCustomerPricesService.massAction({
                state,
                customerPricesPermittedRowLevels,
                parameters: {
                    ...parameters,
                    IsForeignCurrency: isForeignCurrency,
                    UpdatedByDisplayName: user.displayName || '',
                    UpdatedByEmail: user.email || '',
                    UpdatedByUserId: user.id || '',
                },
                userApproverTier,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
            onSuccess?.();
            // invalidateSummaryQuery();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
            onError?.(error);
        },
    });

    const onSubmit = (data: ICustomerPricesMassActionForm) => {
        mutation.mutate({
            state,
            parameters: { ...temporalCustomerPricesFormToParameterMapper(data) },
        });
    };

    return { onSubmit, isLoading: mutation.isLoading };
}

export const temporalCustomerPricesFormToParameterMapper = (
    form: ICustomerPricesMassActionForm
): ITemporalCustomerPricesMassActionParameters => {
    const dateNoTimeUnits =
        form.type === MASS_ACTION_TYPES.VALIDITY_DATES ? new Date(form.value).toLocaleDateString('en-US') : undefined;

    const uom = form.type === MASS_ACTION_TYPES.UOM ? form.value : undefined;

    const value =
        !form.value || ([MASS_ACTION_TYPES.VALIDITY_DATES, MASS_ACTION_TYPES.UOM] as string[]).includes(form.type)
            ? undefined
            : Number(form.value);

    return {
        Type: form.type as ITemporalCustomerPricesMassActionParameters['Type'],
        Field: form.field,
        Value: value,
        Date: dateNoTimeUnits,
        Uom: uom,
    } as ITemporalCustomerPricesMassActionParameters;
};
