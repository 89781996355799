import { useService, useUser } from '@insight2profit/drive-app';
import { DataAccessResponse } from '@price-for-profit/micro-services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { customerPriceStatuses, QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { usePricesInvalidator } from 'shared/providers';
import {
    ISpGetCustomerPricesExceptions,
    ISpTemporalGetCustomerPricesExceptions,
    ITableCustomerPricesExceptions,
    IViewCustomerPrices,
    IViewTemporalCustomerPricesPage,
} from 'shared/types';
import { useCustomerPricesExceptionsSubmitEmailMutation } from './customerPricesExceptionsSubmitEmailMutation';

export type CustomerPricesExceptionsSubmitMutationParams = {
    oldViewRow: ISpGetCustomerPricesExceptions | ISpTemporalGetCustomerPricesExceptions;
    mostRecentComment?: string;
};

export function useCustomerPricesExceptionsSubmitMutation({
    customerPricesRow,
}: {
    customerPricesRow?: IViewCustomerPrices | IViewTemporalCustomerPricesPage;
}) {
    const { customerPricesExceptionsService } = useService();
    const user = useUser();
    const { userApproverTier } = useUserPermissions();
    const { invalidateQuery } = usePricesInvalidator();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const submitEmailMutation = useCustomerPricesExceptionsSubmitEmailMutation();

    const mutation = useMutation<
        {
            editResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
            addResponse: DataAccessResponse<ITableCustomerPricesExceptions>;
            newViewRow: ISpGetCustomerPricesExceptions | ISpTemporalGetCustomerPricesExceptions;
        },
        Error,
        CustomerPricesExceptionsSubmitMutationParams
    >({
        mutationKey: ['CustomerPricesExceptionsSubmit'],
        mutationFn: async ({ oldViewRow }) =>
            await customerPricesExceptionsService.submitRow(oldViewRow, userApproverTier, user),
        onSuccess: (data, variables) => {
            enqueueSnackbar('Exception submit successfully completed', { variant: 'success' });
            invalidateQuery();
            queryClient.invalidateQueries([QUERY_KEYS.customerPrices]);
            queryClient.invalidateQueries([QUERY_KEYS.temporalCustomerPricesPage]);
            if (data.addResponse.data.status !== customerPriceStatuses.APPROVAL_REQUIRED) return;

            if (customerPricesRow) {
                submitEmailMutation.mutateAsync({
                    customerPricesExceptionRow: variables.oldViewRow,
                    customerPricesRow,
                    addResponse: data.addResponse,
                    comment: variables.mostRecentComment ?? '',
                });
            } else {
                enqueueSnackbar('Email failed: Customer Prices Row is missing', { variant: 'error' });
            }
        },
        onError: _error => {
            enqueueSnackbar('There was an error submitting the exception', { variant: 'error' });
        },
    });

    const onSubmit = (
        oldViewRow: ISpGetCustomerPricesExceptions | ISpTemporalGetCustomerPricesExceptions,
        mostRecentComment?: string
    ) =>
        mutation.mutateAsync({
            oldViewRow,
            mostRecentComment,
        });

    return { onSubmit, isLoading: mutation.isLoading };
}
