import { ImmutableObject } from '@hookstate/core';
import { createHash } from '@insight2profit/drive-app';
import { USER_ADDED_SAP_DATASOURCE, customerPriceStatuses } from 'shared/constants';
import {
    IAddExistingSAPCustomerPrices,
    ITableTemporalCustomerPrices,
    IVwDropdownCustomerPricesMaterials,
} from 'shared/types';
import { ICustomerPricesUserPermittedRowLevelsService } from './customerPricesUserPermittedRowLevelsService';
import { IDropDownService } from './generic/dropdownService';
import { AddTableRow, ITemporalTableCrudService } from './generic/temporalTableCrudService';
import { IPricesAddNewService } from './productPricesAddNewService';
import { IUomConversionService } from './uomConversionService';

export class TemporalCustomerPricesAddExistingSAPService
    implements IPricesAddNewService<IAddExistingSAPCustomerPrices, ITableTemporalCustomerPrices> {
    constructor(
        private customerPricesUserPermittedRowLevelsService: ICustomerPricesUserPermittedRowLevelsService,
        private temporalCustomerPricesTableCrudService: ITemporalTableCrudService<ITableTemporalCustomerPrices>,
        private customerPricesMaterialDropdownService: IDropDownService<IVwDropdownCustomerPricesMaterials>,
        private uomConversionService: IUomConversionService
    ) {}
    async addNewRecords(rowsToBeAdded: IAddExistingSAPCustomerPrices[], user: ImmutableObject<app.UserInfo>) {
        const date = new Date();
        const effectiveStartString = date.toISOString();
        const nextYear = new Date(effectiveStartString);
        nextYear.setFullYear(date.getFullYear() + 1);
        const validToString = nextYear.toISOString();

        const response = rowsToBeAdded.map(async aNewRow => {
            const soldToCustomer =
                typeof aNewRow.soldTo === 'string' ? aNewRow.soldTo.split('|')[1] : aNewRow.soldTo?.soldTo;
            const soldToCustomerId =
                typeof aNewRow.soldTo === 'string' ? aNewRow.soldTo.split('|')[0] : aNewRow.soldTo?.soldToId;
            const shipToCustomer =
                typeof aNewRow.shipTo === 'string' ? aNewRow.shipTo.split('|')[1] : aNewRow.shipTo?.shipTo;
            const shipToCustomerId =
                typeof aNewRow.shipTo === 'string' ? aNewRow.shipTo.split('|')[0] : aNewRow.shipTo?.shipToId;
            const materialString =
                typeof aNewRow.material === 'string' ? aNewRow.material.split('|')[1] : aNewRow.material?.material;
            const materialIdString =
                typeof aNewRow.material === 'string' ? aNewRow.material.split('|')[0] : aNewRow.material?.materialId;

            if (
                !aNewRow.businessLine ||
                !soldToCustomer ||
                !soldToCustomerId ||
                !shipToCustomer ||
                !shipToCustomerId ||
                !materialString ||
                !materialIdString ||
                !aNewRow.orgRegion ||
                !aNewRow.application ||
                !aNewRow.salesOrganization ||
                !aNewRow.accountManager
            )
                return Promise.reject();

            const approvalValuesResult = await this.customerPricesMaterialDropdownService.get({
                collectionFilter: {
                    logicalOperator: 'and',
                    filters: [
                        {
                            property: 'businessLine',
                            operator: 'eq',
                            value: aNewRow.businessLine,
                        },
                        {
                            property: 'materialId',
                            operator: 'eq',
                            value: materialIdString,
                        },
                        {
                            property: 'orgRegion',
                            operator: 'eq',
                            value: aNewRow.orgRegion,
                        },
                        {
                            property: 'application',
                            operator: 'eq',
                            value: aNewRow.application,
                        },
                    ],
                },
            });

            if (approvalValuesResult.length === 0 || approvalValuesResult.length > 1) {
                throw Error('Could not determine values needed for approval');
            }

            const correctedApprovalValues = {
                regionalMinimum: approvalValuesResult[0].regionalRevisedMinimum
                    ? this.uomConversionService.convertKaiToKg({
                          kaiPrice: approvalValuesResult[0].regionalRevisedMinimum,
                          materialKaiKgConversionFactor: approvalValuesResult[0].materialKaiKgConversionFactor,
                      })
                    : undefined,
                regionalTarget: approvalValuesResult[0].regionalRevisedTarget
                    ? this.uomConversionService.convertKaiToKg({
                          kaiPrice: approvalValuesResult[0].regionalRevisedTarget,
                          materialKaiKgConversionFactor: approvalValuesResult[0].materialKaiKgConversionFactor,
                      })
                    : undefined,
                globalMinimum: approvalValuesResult[0].globalRevisedMinimum
                    ? this.uomConversionService.convertKaiToKg({
                          kaiPrice: approvalValuesResult[0].globalRevisedMinimum,
                          materialKaiKgConversionFactor: approvalValuesResult[0].materialKaiKgConversionFactor,
                      })
                    : undefined,
                globalTarget: approvalValuesResult[0].globalRevisedTarget
                    ? this.uomConversionService.convertKaiToKg({
                          kaiPrice: approvalValuesResult[0].globalRevisedTarget,
                          materialKaiKgConversionFactor: approvalValuesResult[0].materialKaiKgConversionFactor,
                      })
                    : undefined,
                updatedFloorPrice: approvalValuesResult[0].updatedFloorPrice
                    ? this.uomConversionService.convertKaiToKg({
                          kaiPrice: approvalValuesResult[0].updatedFloorPrice,
                          materialKaiKgConversionFactor: approvalValuesResult[0].materialKaiKgConversionFactor,
                      })
                    : undefined,
            };

            const newCustomerPriceTableRow: AddTableRow<ITableTemporalCustomerPrices> = {
                massActionId: 0,
                newRecordId: createHash(aNewRow.id),
                businessLine: aNewRow.businessLine,
                soldTo: soldToCustomer,
                soldToId: soldToCustomerId,
                shipTo: shipToCustomer,
                shipToId: shipToCustomerId,
                material: materialString,
                materialId: materialIdString,
                application: aNewRow.application,
                marketSegment: aNewRow.marketSegment ?? undefined,
                orgRegion: aNewRow.orgRegion,
                revisedPrice: 0,
                uom: 'KG',
                perQuantity: 1,
                validFrom: effectiveStartString,
                validTo: validToString,
                isScalePrice: false,
                status: customerPriceStatuses.NEEDS_REVIEW,
                dataSource: USER_ADDED_SAP_DATASOURCE,
                customerServiceRep: aNewRow.customerServiceRep ?? undefined,
                salesOrganization: aNewRow.salesOrganization ?? undefined,
                productLine: approvalValuesResult[0].productLine ?? undefined,
                ...correctedApprovalValues,
                effectiveStart: effectiveStartString,
                effectiveEnd: '9999-12-31T23:59:59.999Z',
            };

            const newCustomerPriceTableRowWithId = await this.temporalCustomerPricesTableCrudService.add({
                userInfo: {
                    userDisplayName: user.displayName,
                    userEmail: user.email,
                    userId: user.id,
                },
                tableRow: newCustomerPriceTableRow,
                reason: USER_ADDED_SAP_DATASOURCE,
            });
            await this.customerPricesUserPermittedRowLevelsService.addRlsRow({
                businessLine: newCustomerPriceTableRow.businessLine,
                shipToId: newCustomerPriceTableRow.shipToId,
                materialId: newCustomerPriceTableRow.materialId,
                accountManagerName: aNewRow.accountManager,
                userDisplayName: user.displayName,
            });
            return newCustomerPriceTableRowWithId.data;
        });
        const data = Promise.allSettled(response);
        return data;
    }
}
