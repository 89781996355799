import { Stack } from '@mui/material';

import { ProvideAnalyticsPrerequisites, ProvideAvailableAnalyticsReports } from 'packages/individualAnalytics';

import { useIsDemoMode, useTitle, useUser } from '@insight2profit/drive-app';
import { PageLayout } from 'shared/components/layouts';
import Loading from 'shared/components/routing/loading';
import { useAnalyticsAppInfo } from 'shared/hooks/use-analytics-app-info';
import { ProvideSelectedTemporalCustomerPrice, useExchangeRates } from 'shared/providers';
import { TemporalCustomerPrices } from './temporalCustomerPrices';

export const pageName = 'Customer Prices';

export function TemporalCustomerPricesResolver() {
    const { prerequisites, options } = useAnalyticsAppInfo();
    const isDemoMode = useIsDemoMode();
    useTitle(`${pageName} - ${isDemoMode ? 'PMT' : 'Nouryon'}`);
    const user = useUser();

    const {
        exchangeRates: { isLoading },
    } = useExchangeRates();

    if (isLoading || !user) {
        return <Loading />;
    }

    return (
        <PageLayout pageName={pageName}>
            <Stack gap={2}>
                <ProvideAnalyticsPrerequisites prerequisites={prerequisites} options={options}>
                    <ProvideAvailableAnalyticsReports>
                        <ProvideSelectedTemporalCustomerPrice>
                            <TemporalCustomerPrices user={user} />
                        </ProvideSelectedTemporalCustomerPrice>
                    </ProvideAvailableAnalyticsReports>
                </ProvideAnalyticsPrerequisites>
            </Stack>
        </PageLayout>
    );
}
