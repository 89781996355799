import { CLIENT_ID } from '@insight2profit/drive-app';
import { IDataAccessService } from '@price-for-profit/micro-services';
import { DATABASE_LABEL, TABLE_CUSTOMER_PRICES_USER_PERMITTED_ROW_LEVELS } from 'shared/constants';
import { ITableCustomerPricesUserPermittedRowLevels } from 'shared/types';

interface IAddRlsRowParams {
    businessLine: string;
    shipToId: string;
    materialId: string;
    accountManagerName: string;
    userDisplayName: string;
}

export interface ICustomerPricesUserPermittedRowLevelsService {
    addRlsRow({
        businessLine,
        shipToId,
        materialId,
        accountManagerName,
        userDisplayName,
    }: IAddRlsRowParams): Promise<void>;
}

export class CustomerPricesUserPermittedRowLevelsService {
    constructor(private dasService: IDataAccessService) {}

    async addRlsRow({
        businessLine,
        shipToId,
        materialId,
        accountManagerName,
        userDisplayName,
    }: {
        businessLine: string;
        shipToId: string;
        materialId: string;
        accountManagerName: string;
        userDisplayName: string;
    }) {
        const { data: matchingRlsRows } = await this.dasService.getCollection<
            ITableCustomerPricesUserPermittedRowLevels,
            typeof DATABASE_LABEL
        >({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES_USER_PERMITTED_ROW_LEVELS,
            page: 0,
            pageSize: 100,
            collectionFilter: {
                logicalOperator: 'and',
                filters: [
                    {
                        property: 'businessLine',
                        operator: 'eq',
                        value: businessLine,
                    },
                    {
                        property: 'shipToId',
                        operator: 'eq',
                        value: shipToId.toString(),
                    },
                    {
                        property: 'materialId',
                        operator: 'eq',
                        value: materialId,
                    },
                ],
            },
        });

        if (!matchingRlsRows?.length) {
            const newRlsRow: ITableCustomerPricesUserPermittedRowLevels = {
                businessLine: businessLine,
                shipToId: shipToId,
                materialId: materialId,
                name: accountManagerName,
                isDeleted: false,
                createdBy: userDisplayName,
                updatedBy: userDisplayName,
                updatedReason: 'Created for user added SAP customer',
            };

            await this.dasService.addRow<ITableCustomerPricesUserPermittedRowLevels, typeof DATABASE_LABEL>({
                databaseLabel: DATABASE_LABEL,
                clientId: CLIENT_ID,
                tableId: TABLE_CUSTOMER_PRICES_USER_PERMITTED_ROW_LEVELS,
                payload: newRlsRow,
            });
        }
    }
}
