import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export function createCustomerPricesMassActionValidationInitialState() {
    const dataGridInitialState: GridInitialStatePremium | undefined = {
        pinnedColumns: { left: ['__check__', 'material'] },
    };

    const rowsPerPageOptions = [10, 20, 50, 100];

    return {
        dataGridInitialState,
        rowsPerPageOptions,
    };
}
